.section-productos {
    height: auto;
    width: 100%;
    background-color: var(--app-quinto-color);
    padding-bottom: 2rem;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 7));
    }
}
.slider {
    background-color: white;
    height: 150px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    border-top: 2px solid var(--app-red-color);
    border-bottom: 2px solid var(--app-red-color);
}
.slider::before, .slider::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 150px;
    position: absolute;
    width: 100px;
    z-index: 2;
}
.slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}
.slider::before {
    left: 0;
    top: 0;
}
.slider .slide-track {
    background-color: white;
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
}
.slider .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 150px;
    width: 250px;
}
a {
    text-decoration: none;
}
.card {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px!important;
    z-index: 1;
    background-color: var(--app-cuarto-color);
    color: #000000;
}
.card:hover, .card:active, .card:focus {
    z-index: 2;
    cursor: pointer;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: 0px 0px 25px 1px rgba(125,125,125,0.75);
}
.pic-product {
    width: 100%;
    height: 186px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--app-segundo-color);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.pic {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: relative;
}
.pic-img {
    width: 90%;
    height: 85%;
    border-radius: 50%;
    object-fit: cover;
}
.desc-product {
    width: 100%;
    height: 200px;
    background-color: #e1e1e1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
h2 {
    color: var(--app-primer-color);
    text-align: center;
    margin-top: 2rem;
}
.h4-product {
    margin: 0;
    font-size: 0.8rem;
    color: var(--app-red-color);
    font-weight: 500;
}
p {
    margin: 0;
    padding: 10px;
    font-size: 18px;
    font-weight: normal;
}
.products {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-flow: row wrap;
    justify-content: center;
}
.product {
    position: relative;
    width: 256px;
    height: 416px;
    margin: 20px;
    color: white;
    font-weight: bold;
    font-size: 3em;
    text-align: center;
}

/* common */
.ribbon {
    width: 127px;
    height: 120px;
    overflow: hidden;
    position: absolute;
}
.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid var(--app-sexto-color);
}
.ribbon span {
    position: absolute;
    display: block;
    width: 235px;
    padding: 3px 0;
    background-color: var(--app-sexto-color);
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 600 14px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
}
.ribbon-top-right {
    top: -2px;
    right: -2px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
}
.ribbon-top-right::before {
    top: 0;
    left: 0;
}
.ribbon-top-right::after {
    bottom: 0;
    right: 0;
}
.ribbon-top-right span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
}
.part-product {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 0.5rem;
    color: #000;
}
