.footer-contacto {
    height: 40px;
    background-color: var(--app-red-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 100;
    text-align: center;
    font-size: 0.6rem;
}
h2 {
    color: var(--app-primer-color);
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
}
@media (min-width: 999px) {
    .footer-contacto {
        font-size: 1rem;
    }
}
b {
    font-size: 0.7rem;
}
