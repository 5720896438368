.section-nosotros {
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 1rem;
}
.section-nosotros h2 {
    color: var(--app-red-color);
}
.left-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
    width: 100%;
    color: var(--app-primer-color);
    font-size: 1rem;
    text-align: center;
}
.right-section {
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 1rem;
    background-color: var(--app-quinto-color);
}
.right-section div {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
h5 a {
    color: var(--app-tercer-color);
    font-size: 0.5rem;
}
@media (min-width: 999px) {
    .section-nosotros {
        padding-bottom: 3rem;
    }
    h5 a {
        font-size: 1rem;
    }
    .left-section p {
        margin: 0 10rem;
        font-size: 1.5rem;
    }
}
